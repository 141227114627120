import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public notifications$: Subject<any> = new Subject();

  constructor() {
  }
}
