import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, RouterOutlet} from '@angular/router';
import {isNullOrUndefined} from 'util';
import {slideInAnimation} from 'src/app/pages/account/account-navigation.animation';

@Component({
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  animations: [slideInAnimation]
})
export class AccountComponent implements OnInit {
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    const urlPrefix = this.getUrlPrefix(this.activatedRoute);
    const navigationItems = this.activatedRoute.snapshot.routeConfig.children.filter(route =>
      route.path !== '' && !isNullOrUndefined(route.data) && route.data.navigation
    ).map(route => ({name: route.data.title ? route.data.title : '', url: `${urlPrefix}/${route.path}`}));
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.hasOwnProperty('animation') ? outlet.activatedRouteData['animation'] : 'default';
  }

  private getUrlPrefix(route: ActivatedRoute, url: string = ''): string {
    const parent = route.parent;
    if (isNullOrUndefined(parent)) {
      return url;
    }
    const routeURL: string = parent.snapshot.url.map(segment => segment.path).join('/');
    if (routeURL !== '') {
      url += `/${routeURL}`;
    }
    return this.getUrlPrefix(parent, url);
  }
}
