<div fxLayout fxLayoutAlign="center center" class="auth-wrapper">
  <mat-card class="auth-card">
    <mat-card-header>
      <mat-card-title>Sign In</mat-card-title>
    </mat-card-header>
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <mat-card-content fxLayout="column">
        <mat-form-field>
          <input matInput formControlName="token" placeholder="Token" type="password" required>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="accent" type="submit">Login</button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
