import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard} from '@eidos/guards';

import {LoginComponent} from './pages/login/login.component';

const routes: Routes = [
  {path: '', redirectTo: 'account', pathMatch: 'full'},
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(mod => mod.AccountModule),
    canActivate: [AuthGuard]
  },
  {path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
