import {MatTableDataSource} from '@angular/material/table';

export function TableSearch<T extends { new(...args: any[]): {} }>(constructor: T) {
  return class extends constructor {
    dataSource: MatTableDataSource<any>;

    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  };
}
