import {NgModule, APP_INITIALIZER, APP_BOOTSTRAP_LISTENER} from '@angular/core';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule, Title, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GestureConfig} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieService} from 'ngx-cookie-service';

import {AppConfigService, PageTitleService} from '@eidos/services';
import {AuthInterceptor, ErrorHandlerInterceptor} from '@eidos/interceptors';

import {LoginModule} from './pages/login/login.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PageBreadcrumbsService} from 'src/app/services/buisness/page-breadcrumbs.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export function loadConfig(config: AppConfigService) {
  return () => config.load();
}

export function bootstrapTitle(pageTitle: PageTitleService) {
  return () => pageTitle.bootstrap();
}

export function bootstrapBreadcrumbs(pageBreadcrumbs: PageBreadcrumbsService) {
  return () => pageBreadcrumbs.bootstrap();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    LoginModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      multi: true,
      deps: [AppConfigService]
    },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: bootstrapTitle,
      multi: true,
      deps: [PageTitleService]
    },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: bootstrapBreadcrumbs,
      multi: true,
      deps: [PageBreadcrumbsService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    CookieService,
    Title
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
