import {Injectable} from '@angular/core';

import {ApiService} from './api.service';

import {RestApiListService} from '@eidos/abstractions';

@Injectable()
export class SspEndpointFilterItemApiService extends RestApiListService<any> {
  constructor(
    public apiService: ApiService
  ) {
    super(apiService);
  }

  generateUrl(...parameters: Array<string>): string {
    return `api/admin/ssp/endpoint/${parameters[0]}/filter/${parameters[1]}/items`;
  }
}
