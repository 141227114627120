import {Injectable} from '@angular/core';
import {Observable, of, zip} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {DspEndpointFilterApiService} from '../api/dsp-endpoint-filter-api.service';

const FILTER_TYPE_MAP = {
  country: 'Country',
  subid: 'SubId',
  ip: 'IP'
};

@Injectable()
export class DspEndpointFilterService {
  constructor(
    private filterApi: DspEndpointFilterApiService
  ) {
  }

  public getList(endpointId: string): Observable<any> {
    const allRequests = Object.keys(FILTER_TYPE_MAP).map((type) => this.get(endpointId, type));
    return zip(...allRequests).pipe(map((filters) => filters.filter((filter) => filter !== null)));
  }

  public get(endpointId: string, type: string) {
    return this.filterApi.get(endpointId, type).pipe(
      catchError((error) => this.error404(error)),
      map((filter) => this.mapFilter(filter, FILTER_TYPE_MAP[type], type))
    );
  }

  public create(endpointId: string, type: string) {
    return this.filterApi.create({mode: 'INCLUDE', status: 'DISABLED'}, endpointId, type);
  }

  public update(endpointId: string, type: string, body) {
    return this.filterApi.update(body, endpointId, type);
  }

  public delete(endpointId: string, type: string) {
    return this.filterApi.delete(endpointId, type);
  }

  private error404(error): Observable<null> {
    if (error.status === 404) {
      return of(null);
    }
  }

  private mapFilter(filter, typeName: string, type: string) {
    if (filter) {
      return {...filter, name: typeName, type};
    }
    return filter;
  }
}
