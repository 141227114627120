import {Input, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Observable} from 'rxjs';

export class TableComponent<Row> {
  @Input() rows$: Observable<Array<Row>>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public dataSource: MatTableDataSource<Row>;
  public isLoading = true;

  public initTableDataSource() {
    this.rows$.subscribe(
      (rows: Array<Row>) => {
        this.dataSource = new MatTableDataSource<Row>(rows);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (error) => this.isLoading = false
    );
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
