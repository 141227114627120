import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {SspEndpointFilterItemApiService} from '../api/ssp-endpoint-filter-item-api.service';

@Injectable()
export class SspEndpointFilterItemService {
  constructor(
    private filterItemApi: SspEndpointFilterItemApiService
  ) {
  }

  public getList(endpointId: string, type: string): Observable<any> {
    return this.filterItemApi.getList(endpointId, type);
  }

  public updateList(endpointId: string, type: string, body) {
    return this.filterItemApi.updateList(body, endpointId, type);
  }

  public deleteList(endpointId: string, type: string, body) {
    return this.filterItemApi.deleteList(body, endpointId, type);
  }
}
