import {Injectable} from '@angular/core';

import {RestApiItemService} from '@eidos/abstractions';

import {ApiService} from './api.service';

@Injectable()
export class DspEndpointFilterApiService extends RestApiItemService<any> {
  constructor(
    public apiService: ApiService
  ) {
    super(apiService);
  }

  public generateUrl(...parameters: Array<string>) {
    return `api/admin/dsp/endpoint/${parameters[0]}/filter/${parameters[1]}`;
  }
}
