import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {BehaviorSubject, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  public title = 'Eidos';
  public title$: BehaviorSubject<string> = new BehaviorSubject(this.title);

  constructor(
    public titleService: Title,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
  }

  public setTitle(title: string) {
    this.title$.next(title);
  }

  public bootstrap() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child.firstChild) {
          child = child.firstChild;
        }
        if (child.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return this.titleService.getTitle();
      })
    ).subscribe((stateTitle: string) => {
      const newTitle = `${this.title} - ${stateTitle}`;
      this.titleService.setTitle(newTitle);
      this.title$.next(newTitle);
    });
    return of().toPromise();
  }
}
