import {ApiService} from '@eidos/services';

export abstract class RestApiListService<BodyType> {
  protected constructor(
    public apiService: ApiService
  ) {
  }

  abstract generateUrl(...parameters: Array<string>);

  public getList(...parameters: Array<string>) {
    return this.apiService.get(this.generateUrl(...parameters));
  }

  public updateList(body: BodyType, ...parameters: Array<string>) {
    return this.apiService.patch(this.generateUrl(...parameters), body);
  }

  public deleteList(body: BodyType, ...parameters: Array<string>) {
    return this.apiService.delete(this.generateUrl(...parameters), {body});
  }
}
