import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {SspEndpointApiService} from '../api/ssp-endpoint-api.service';

@Injectable()
export class SspEndpointService {
  constructor(
    private endpointApi: SspEndpointApiService
  ) {
  }

  public getList(): Observable<Array<any>> {
    return this.endpointApi.getList();
  }

  public get(endpointId: string) {
    return this.endpointApi.get(endpointId).pipe(
      map((properties) => {
        const id = properties.find((property) => property.key === 'Id');
        const name = properties.find((property) => property.key === 'Name');
        return {id: id ? id.val : '', name: name ? name.val : ''};
      })
    );
  }
}
