import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {Logger} from '@eidos/decorators';
import {NotificationsService} from '@eidos/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@Logger()
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Eidos';
  public titleStream: BehaviorSubject<string> = new BehaviorSubject(this.title);
  private notificationSubscription: Subscription;

  constructor(
    private notifications: NotificationsService,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.initLanguageSettings();
    this.notifications.notifications$.subscribe((notification) => {
      this.snackBar.open(notification.error ? notification.error.message : notification.message, 'Close', {
        duration: 10000,
      });
    });
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
  }

  private initLanguageSettings() {
    // ADD compare with navigator.language for autocomplete actual language
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }
}
