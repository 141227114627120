import {ApiService} from '@eidos/services';

export abstract class RestApiItemService<BodyType> {
  protected constructor(
    public apiService: ApiService
  ) {
  }

  abstract generateUrl(...parameters: Array<string>);

  public get(...parameters: Array<string>) {
    return this.apiService.get(this.generateUrl(...parameters));
  }

  public create(body: BodyType, ...parameters: Array<string>) {
    return this.apiService.post(this.generateUrl(...parameters), body);
  }

  public update(body: BodyType, ...parameters: Array<string>) {
    return this.apiService.put(this.generateUrl(...parameters), body);
  }

  public delete(...parameters: Array<string>) {
    return this.apiService.delete(this.generateUrl(...parameters));
  }
}
