import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable, of} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private _apiDomain: BehaviorSubject<string> = new BehaviorSubject(null);
  private _map: BehaviorSubject<Object> = new BehaviorSubject(null);

  constructor(
    public http: HttpClient
  ) {
  }

  get apiDomain(): BehaviorSubject<string> | Observable<string> {
    return this._apiDomain;
  }

  get map(): BehaviorSubject<Object> {
    return this._map;
  }

  public load() {
    /*this.http
      .get(`/conf/world-highres2.geo.json`)
      .subscribe(map => this._map.next(map));*/
    return of().toPromise();
  }
}
