import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable()
export class SspEndpointApiService {
  public baseUrl = 'api/admin/redash/ssp/endpoint';

  constructor(
    private apiService: ApiService
  ) {
  }

  getList() {
    return this.apiService.get(`${this.baseUrl}/query/filter`);
  }

  get(endpointId: string) {
    return this.apiService.get(`${this.baseUrl}/${endpointId}/details`);
  }
}
