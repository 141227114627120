import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {throwError as observableThrowError, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';

import {NotificationsService} from '@eidos/services';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private notifications: NotificationsService,
    private cookieService: CookieService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error): Observable<any> => {
      if (error instanceof HttpErrorResponse) {
        switch ((error as HttpErrorResponse).status) {
          case 400:
            this.notifications.notifications$.next(error);
            return observableThrowError(error);
          case 401:
            this.notifications.notifications$.next(error);
            this.cookieService.deleteAll();
            this.router.navigate(['/login']);
            return observableThrowError(error);
          case 404:
            return observableThrowError(error);
          case 409:
            this.notifications.notifications$.next(error);
            return observableThrowError(error);
        }
      } else {
        return observableThrowError(error);
      }
    }));
  }
}
