import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';

import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private cookieService: CookieService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthorised = this.isAuthorised();
    if (!isAuthorised) {
      this.cookieService.deleteAll();
      this.router.navigate(['/login']);
    }
    return isAuthorised;
  }

  private isAuthorised(): boolean {
    return this.cookieService.get('token') !== '';
  }
}
