import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.cookieService.get('token');
    return next.handle(this.addToken(request, token));
  }

  private addToken(request: HttpRequest<any>, token: string, header: string = 'op-auth-token'): HttpRequest<any> {
    const headers = {[header]: token};
    return token ? request.clone({setHeaders: headers}) : request;
  }
}
