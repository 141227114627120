import {Component} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';


@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public loginForm: FormGroup = new FormGroup({
    token: new FormControl('', [Validators.required])
  });

  constructor(
    private cookieService: CookieService,
    private router: Router
  ) {
  }

  login() {
    const token = this.loginForm.value.token;
    this.cookieService.set('token', token);
    this.router.navigate(['/account']);
  }
}
