import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _apiUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) {
  }

  public get apiUrl(): string {
    return this._apiUrl;
  }

  public request(method: string, url: string, options: { [key: string]: string } = {}): Observable<any> {
    return this.http.request(method, url, this.createHttpRequestOptions(options));
  }

  public get(url: string, options: { [key: string]: string } = {}): Observable<any> {
    return this.http.get(this.fullUrl(url), this.createHttpRequestOptions(options));
  }

  public post(url: string, body, options: { [key: string]: string } = {}): Observable<any> {
    return this.http.post(this.fullUrl(url), body, this.createHttpRequestOptions(options));
  }

  public put(url: string, body, options: { [key: string]: string } = {}): Observable<any> {
    return this.http.put(this.fullUrl(url), body, this.createHttpRequestOptions(options));
  }

  public patch(url: string, body, options: { [key: string]: string } = {}): Observable<any> {
    return this.http.patch(this.fullUrl(url), body, this.createHttpRequestOptions(options));
  }

  public delete(url: string, options: { [key: string]: any } = {}): Observable<any> {
    return this.http.delete(this.fullUrl(url), options);
  }

  private fullUrl(url: string): string {
    const splittedUrl = url.split('//');
    const firstElementIsProtocol = splittedUrl[0] === '' || splittedUrl[0].includes(':');

    return (splittedUrl.length === 2 && firstElementIsProtocol) ? url : `${this._apiUrl}${url}`;
  }

  private createHttpRequestOptions(options: { [key: string]: string }): { params: HttpParams } {
    let params = new HttpParams();
    Object.keys(options).forEach((optionKey) => {
      params = params.append(optionKey, options[optionKey]);
    });
    return {params};
  }

}
