import {Injectable} from '@angular/core';

import {environment} from 'src/environments/environment';
import {COUNTRIES} from '../moks/countries.mock';

@Injectable()
export class DropdownsAutocompletesService {
  protected useMock = environment.mocks;

  public get countries() {
    return COUNTRIES;
  }
}
