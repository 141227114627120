import {Injectable} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {of, BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';

@Injectable({
  providedIn: 'root'
})
export class PageBreadcrumbsService {
  public breadcrumbs: Array<any> = [];
  public breadcrumbs$: BehaviorSubject<Array<any>> = new BehaviorSubject(this.breadcrumbs);
  public keysMap$: BehaviorSubject<{ [key: string]: string }> = new BehaviorSubject({});

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
  }

  public bootstrap() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.breadcrumbs$.next(this.createBreadcrumbs(this.activatedRoute.root));
    });
    return of().toPromise();
  }


  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Array<any> = []): Array<any> {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      const routeBreadcrummbsSettings = child.snapshot.data['breadcrumbs'];
      if (!isNullOrUndefined(routeBreadcrummbsSettings)) {
        const label = routeBreadcrummbsSettings['label'];
        const displayLink = routeBreadcrummbsSettings['displayLink'];
        const breadcrumb = {label, url, displayLink};
        if (isNullOrUndefined(label)) {
          breadcrumb['key'] = routeBreadcrummbsSettings['key'];
        }
        if (this.isBreadcrumbExist(breadcrumbs, breadcrumb)) {
          breadcrumbs.push(breadcrumb);
        }
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  private isBreadcrumbExist(breadcrumbs, breadcrumb) {
    if (breadcrumbs.length === 0) {
      return true;
    }
    return breadcrumbs[breadcrumbs.length - 1].label !== breadcrumb.label && breadcrumbs[breadcrumbs.length - 1].url !== breadcrumb.url;
  }
}
